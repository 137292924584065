import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CardIcon from 'shared/assets/card-icon.svg?react';
import TestimonialImage from 'shared/assets/testimonial-1.png';
import TestimonialImage2 from 'shared/assets/testimonial-2.png';
import { trackPage } from 'shared/helpers/AnalyticsHelpers';
import {
  Content,
  ContentLeft,
  ContentLeftInner,
  ContentRight,
  ContentRightInner,
} from 'shared/layout/Content';
import { Header } from 'shared/layout/Header';
import { Button } from 'shared/ui/Button';
import { Button as SDButton, Stack } from '@superdispatch/ui';
import styled from 'styled-components';
import { GetStartedNotes } from '../core/GettingStartedNotes';
import { Modal } from '../core/Modal';
import { TestimonialCardWithImage } from '../core/TestimonialCard';
import { Step, Wizard } from '../core/Wizard';
import { useSignupCarrier } from './core/useSignupCarrier';
import { AdditionalInfo } from './steps/AdditionalInfo';
import { ContactInfo } from './steps/ContactInfo';
import { CreateAccount } from './steps/CreateAccount';
import { VerifyUSDOT } from './steps/VerifyUSDOT';
import { Dialog, DialogContent, Link, Typography } from '@material-ui/core';
import JoinRequestIcon from 'shared/assets/join-request-icon.png';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 24px;
    max-width: 464px;
  }
`;

const CardBody = styled.div`
  font-size: 18px;
  line-height: 28px;
  margin: 45px 0;
`;
const CardFooter = styled.div`
  border-top: 1px solid #e1e5ea;
  padding-top: 25px;
  line-height: 20px;
  color: #6d7480;
`;
const CardQuouteAuthor = styled.div`
  line-height: 24px;
`;
const ModalThankYouText = styled.div`
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.35px;
  margin: 20px auto;
  font-weight: 600;
  max-width: 200px;
`;
const ModalMessage = styled.div`
  color: #6a707c;
  text-align: center;
  margin-bottom: 5px;
`;
const ModalMessageBold = styled(ModalMessage)`
  font-weight: 600;
  margin-bottom: 20px;
`;
const ModalReturnButton = styled(Button)`
  margin-top: 48px;
  min-width: 273px;
`;
const StyledContent = styled(Content)`
  min-height: 115vh;
  @media (max-width: 768px) {
    min-height: 100vh;
  }
`;

interface SignupCarrierProps {
  routeValues: string[];
}

export function SignupCarrier({ routeValues }: SignupCarrierProps) {
  const params = useParams();
  const navigate = useNavigate();
  const {
    createAccountTitle,
    setCreateAccountTitle,
    handleSubmit,
    isSubmitting,
    initialValues,
    setCarrierData,
    setCaptchaToken,
    joinEmail,
    setIsRegistered,
    captchaToken,
    verifiedUSDOT,
    setVerifiedUSDOT,
    carrierData,
  } = useSignupCarrier();

  React.useEffect(() => {
    trackPage('Carrier Sign Up');
  }, []);

  return (
    <StyledContent>
      <ContentLeft>
        <Header half={true} type="carrier" />
        <ContentLeftInner>
          <Wizard
            routeValues={routeValues}
            currentRouteValue={params.step}
            onNextRoute={(route) => {
              navigate({
                pathname: `/carrier/${route}`,
                search: location.search,
              });
            }}
            onFirstRoute={(route) => {
              navigate(
                {
                  pathname: `/carrier/${route}`,
                  search: location.search,
                },
                {
                  replace: true,
                },
              );
            }}
            onFormSubmit={handleSubmit}
            initialValues={initialValues}
          >
            <Step
              title={createAccountTitle}
              render={() => (
                <CreateAccount onUpdateTitle={setCreateAccountTitle} />
              )}
            />
            <Step title="Contact Info" render={() => <ContactInfo />} />
            <Step
              title="Additional Info"
              render={({ wizardData }) => (
                <AdditionalInfo
                  submitting={isSubmitting}
                  wizardData={wizardData}
                  onCaptchaTokenChange={(token) => {
                    setCaptchaToken(token);
                  }}
                />
              )}
            />
            <Step
              title="USDOT Verification"
              render={({ values, wizardData }) => (
                <VerifyUSDOT
                  usdot={values.usdot}
                  wizardData={wizardData}
                  submitting={isSubmitting}
                  captchaToken={captchaToken}
                  verifiedUSDOT={verifiedUSDOT}
                  setVerifiedUSDOT={setVerifiedUSDOT}
                  onCaptchaTokenChange={(token) => {
                    setCaptchaToken(token);
                  }}
                  onCarrierStatusChange={(status, data) => {
                    setIsRegistered(status);
                    setCarrierData(data);
                  }}
                />
              )}
            />
          </Wizard>
        </ContentLeftInner>
      </ContentLeft>
      <ContentRight>
        <ContentRightInner>
          {params.step === 'create-account' &&
          createAccountTitle === 'Create Account' ? (
            <>
              <GetStartedNotes />
              <TestimonialCardWithImage
                src={TestimonialImage}
                body={
                  <div style={{ margin: '32px', marginLeft: '80px' }}>
                    <CardIcon />
                    <CardBody>
                      “With Super Dispatch, our cash flow is now 1,000 times
                      quicker!”
                    </CardBody>
                    <CardFooter>
                      <CardQuouteAuthor> Lori W.,</CardQuouteAuthor>
                      <CardQuouteAuthor>
                        Right Way Auto Transport
                      </CardQuouteAuthor>
                    </CardFooter>
                  </div>
                }
              />
            </>
          ) : (
            <TestimonialCardWithImage
              src={TestimonialImage2}
              body={
                <div style={{ margin: '32px', marginLeft: '80px' }}>
                  <CardIcon />
                  <CardBody>
                    “I don’t have to use anything (other than Super Dispatch)…it
                    takes me 6 or 7 minutes now just to cut and copy the VIN
                    numbers, I already have our brokers contacts saved in the
                    system, it’s just easy to use.”
                  </CardBody>
                  <CardFooter>
                    <CardQuouteAuthor> Kevin Parada,</CardQuouteAuthor>
                    <CardQuouteAuthor> JP Auto Transport</CardQuouteAuthor>
                  </CardFooter>
                </div>
              }
            />
          )}
        </ContentRightInner>
      </ContentRight>

      <StyledDialog
        open={!!joinEmail}
        disableEscapeKeyDown={true}
        maxWidth="sm"
      >
        <DialogContent>
          <Stack space={'medium'} align={'center'}>
            <img src={JoinRequestIcon} alt="Join Request" />
            <Stack>
              <Typography variant="h2" align="center">
                Waiting for Confirmation
              </Typography>
              <Typography align="center" variant="subtitle2">
                An email has been sent to{' '}
                <Typography variant="body1" display="inline">
                  {carrierData?.name}
                </Typography>{' '}
                to review your join request.{' '}
                {carrierData?.phone_numbers && (
                  <>
                    You can also contact dispatcher at{' '}
                    <Link
                      href={`tel:${carrierData?.phone_numbers}`}
                      color="primary"
                      underline="none"
                      noWrap={true}
                    >
                      {carrierData?.phone_numbers}
                    </Link>
                  </>
                )}
              </Typography>
            </Stack>
            <SDButton
              onClick={() => {
                window.location.href = 'https://superdispatch.com/';
              }}
              size="large"
              variant="contained"
            >
              Got It
            </SDButton>
          </Stack>
        </DialogContent>
      </StyledDialog>
    </StyledContent>
  );
}
